import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_OFFERS, ADMIN_PRODUCTS_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/userProducts/Editor';
import { TAB_OFFERS, TAB_PRODUCTS } from 'Components/pages/admin/Offers/component';

export const TAB_DATA = 'data';
export const TAB_SPECIALIZATIONS_LIST = 'specializationsList';
export const TAB_LOGS = 'logs';

export default class AdminUserProductsManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        userProduct: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { location, history, userProduct } = this.props;

        return (
            <StyledComponent
                className="admin-user-products-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={userProduct}
                >
                    {userProduct && userProduct.data && (
                        <PageContent
                            breadcrumbs={[{
                                to: withVariables(ADMIN_OFFERS.path, {}, { tab: TAB_OFFERS }),
                                label: 'Oferty',
                            }, {
                                to: withVariables(ADMIN_OFFERS.path, {}, { tab: TAB_PRODUCTS }),
                                label: 'Produkty',
                            }, {
                                to: withVariables(ADMIN_PRODUCTS_MANAGE.path, { id: userProduct.data.id }),
                                label: 'Edytuj produkt',
                            }]}
                        >
                            <TabNavigation
                                location={location}
                                history={history}
                                headline="Edytuj produkt"
                                subHeadline={userProduct.data.name}
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <Editor
                                            location={location}
                                            history={history}
                                            data={userProduct.data}
                                        />
                                    ),
                                }]}
                            />
                        </PageContent>
                    )}
                </Page>
            </StyledComponent>
        );
    }
}