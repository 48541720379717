import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class PanelUserProductsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            update: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {},
    };

    componentDidMount = () => {
        const { data } = this.props;

        if (data) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    dataToFormState = data => ({
        ...data,
    })

    formStateToData = formState => ({
        ...formState,
    })

    onSubmit = formState => {
        return this.onUpdate(formState)
    }

    onUpdate = formState => {
        const { data, actions } = this.props;

        return actions.update({
            ...this.formStateToData(formState),
            id: data.id,
        });
    }

    render() {
        const { data, location, history } = this.props;
        const { formState } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="panel-user-products-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data && data.id),
                        label: 'id',
                        value: formState.id,
                    }, {
                        visible: true,
                        label: 'Nazwa produktu',
                        value: formState?.product?.name,
                    }, {
                        visible: true,
                        label: 'Nazwa wyświetlana klientowi',
                        value: formState?.product?.displayName,
                    }, {
                        visible: true,
                        label: 'id produktu',
                        value: formState?.productId
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'priceGross',
                            label: 'Cena brutto',
                            inputProps: {
                                type: 'number',
                            },
                        }, {
                            type: 'inputsList',
                            name: 'pricingMatrix',
                            label: 'Cennik',
                            inputProps: {
                                config: [{
                                    name: 'quantity',
                                    label: 'Ilość',
                                    placeholder: 'Ilość',
                                    type: 'number',
                                    required: true,
                                }, {
                                    name: 'valueGross',
                                    label: 'Cena za 1 szt.',
                                    placeholder: 'Cena za 1 szt.',
                                    type: 'number',
                                    required: true,
                                }],
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}

